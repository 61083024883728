import {
  wavesSeparator,
  slantedLineSeparatorDark,
  logoLight,
  spotify,
} from "../../assets";
import { Fade, Slide } from "react-awesome-reveal";
import { blaqByteSocialLinks, trackEvent } from "../../helpers";
import {
  IFrame,
  Container,
  MainHeading,
  SubHeading,
  HeadingSubtext,
  SectionContainer,
  Separator,
  Icon,
  SpotifyIcon,
  BannerContainer,
} from "./podcast.styles";
import { Footer } from "../../components/footer";
import { Background } from "../../components/background";
import { useViewportDimensions } from "../../hooks/viewPortDimensions";

export const Podcast = () => {
  const { width } = useViewportDimensions();
  const isMobile = width <= 990;


   const navigateAndTrack = (navigateTo: string, event: string) => {
     window.open(navigateTo, "_blank");
     trackEvent(event);
   };

  return (
    <>
      <Background />
      <BannerContainer>
        <Container>
          <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
            <MainHeading>Hosted by Renée Louise</MainHeading>
          </Fade>
          <Slide triggerOnce>
            <SubHeading>Blaq Byte Podcast</SubHeading>
            <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
              <HeadingSubtext isMobile={isMobile}>
                {blaqByteSocialLinks.map((link) => (
                  <Icon
                    src={link.src.light}
                    alt={link.alt}
                    onClick={() =>
                      navigateAndTrack(`${link.url}`, `${link.alt} clicked`)
                    }
                  />
                ))}
              </HeadingSubtext>
            </Fade>
          </Slide>
        </Container>
        <SpotifyIcon
          src={spotify}
          alt="spotify icon"
          onClick={() =>
            navigateAndTrack(
              "https://open.spotify.com/show/2ZE8HtuTa7104cBxSsxIrp",
              "Spotify icon clicked"
            )
          }
        />
      </BannerContainer>
      <Separator src={wavesSeparator} alt="" />
      <SectionContainer color="#fff">
        <IFrame
          id="player_iframe"
          src="https://www.buzzsprout.com/2115341?client_source=large_player&iframe=true&referrer=https%3A%2F%2Fwww.buzzsprout.com%2F2115341%2Fpodcast%2Fembed"
          loading="lazy"
          width="100%"
          height="375"
          frameBorder="0"
          scrolling="no"
          title="Blaq Byte Podcast"
        />
      </SectionContainer>
      <Footer
        separator={slantedLineSeparatorDark}
        color="#fff"
        logo={logoLight}
      />
    </>
  );
};
