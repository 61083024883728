import Typewriter from "typewriter-effect";

interface TypingEffectProps {
  phrases : string[];
}
export const TypingEffect = ({ phrases }: TypingEffectProps) => {

  return (
    <Typewriter
      options={{
        strings: phrases,
        autoStart: true,
        loop: true,
      }}
    />
  );
};
