import styled, { css } from "styled-components";

interface StyleProps {
  brightness: string;
}

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(rgb(21, 13, 107) 1.1%, rgb(188, 16, 80) 130.5%);
`;


export const Video = styled.video<StyleProps>(
  ({ brightness }: StyleProps) => css`
    object-fit: cover;
    filter: brightness(${brightness});
  `
);

export const VisuallyHidden = styled.span`
  visibility: hidden;
`;
