import styled, { css } from "styled-components";
import { FaArrowCircleRight } from "react-icons/fa";

interface StyleProps {
  isMobile: boolean;
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TestimonialContainer = styled.div<StyleProps>(
  ({ isMobile }: StyleProps) => css`
    margin: ${isMobile ? "0px" : "24px"};
  `
);

const Text = styled.p`
  font-size: 24px;
  text-align: center;
`;

const AuthorText = styled(Text)`
  font-weight: 600;
  text-align: center;
`;

const ArrowGroup = styled.div`
  display: flex;
  justify-content: center;
`;

const PrevArrow = styled(FaArrowCircleRight)<StyleProps>(
  ({ isMobile }: StyleProps) => css`
    border-radius: 50%;
    width: 50px;
    height: fit-content;
    cursor: pointer;
    padding: 12px;
    transform: rotate(180deg);
  `
);

const NextArrow = styled(PrevArrow)`
  transform: unset;
`;

const Image = styled.img<StyleProps>(
  ({ isMobile }: StyleProps) => css`
    width: ${isMobile ? "100%" : "40rem"};
    height: ${isMobile ? "30rem" : "62rem"};
    object-fit: cover;
    margin-bottom: 12px;
  `
);

export {
  Container,
  TestimonialContainer,
  Text,
  AuthorText,
  ArrowGroup,
  PrevArrow,
  NextArrow,
  Image,
};
