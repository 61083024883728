import React from "react";
import { useNavigate } from "react-router-dom";
import {
  wavesSeparator,
  lineSeparator,
  slantedLineSeparator,
  slantedLineSeparatorDark,
  singleLineWave,
  logoLight,
} from "../../assets";
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import {
  extraStories,
  mobilePhrases,
  phrases,
  testimonials,
  certificates,
  trackEvent,
} from "../../helpers";
import {
  Container,
  MainHeading,
  SubHeading,
  Section,
  SectionContainer,
  Separator,
  TextContainer,
  StoryImage,
  Text,
  AnotherSection,
  CustomerStoriesContainer,
  Story,
  Type,
  Description,
  ButtonGroup,
  Button,
  BottomSeparator,
  ValueText,
  IFrame,
  CertificateImage,
} from "./home.styles";
import { Footer } from "../../components/footer";
import { Background } from "../../components/background";
import { ContactMini } from "../../components/contact-mini";
import { TypingEffect } from "../../components/typing-effect/typing-effect";
import { Slider } from "../../components/slider/slider";
import "rc-slider/assets/index.css";
import { useViewportDimensions } from "../../hooks/viewPortDimensions";

export const Home = () => {
  const navigate = useNavigate();
  const { width } = useViewportDimensions();
  const isMobile = width <= 728;

  const desktopCopy =
    "Empowering underrepresented career-switchers to conquer the tech industry with passion and precision.";
  const mobileCopy = "Conquering the tech industry with passion and precision.";

  const navigateAndTrack = (navigateTo: string, event: string) => {
    navigate(navigateTo);
    trackEvent(event);
  };

  const handleViewResume = () => {
    window.open("/resume");
    trackEvent ('View Resume button click')
  }

  return (
    <>
      <Background />
      <Container>
        <Slide triggerOnce>
          <SubHeading>
            <TypingEffect phrases={isMobile ? mobilePhrases : phrases} />
          </SubHeading>
        </Slide>
        <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
          <MainHeading>{isMobile ? mobileCopy : desktopCopy}</MainHeading>
        </Fade>
      </Container>
      <Separator src={wavesSeparator} alt="" />
      <SectionContainer color="#fff">
        <AnotherSection>
          <ValueText>Blaq Byte Podcast</ValueText>
          <IFrame
            src="https://www.buzzsprout.com/2115341/12425380-career-switcher-series-episode-1-switching-from-software-engineering-to-product-management-at-goldman-sachs-ft-seun-afolayan?client_source=small_player&iframe=true"
            loading="lazy"
            width="100%"
            height="200"
            frameBorder="0"
            scrolling="no"
            title="Switching from Software Engineering to Product Management at Goldman Sachs ft Seun Afolayan "
          ></IFrame>
          <ButtonGroup>
            <Button
              onClick={() =>
                navigateAndTrack("/podcast", "View all podcast episodes click")
              }
            >
              View all episodes
            </Button>
          </ButtonGroup>
        </AnotherSection>
        <Separator src={lineSeparator} alt="" />
        <AnotherSection color="#fff">
          <Text>Speaking Engagements</Text>
          <CustomerStoriesContainer>
            {extraStories.map((story, key) => {
              return (
                <>
                  <Zoom key={key} delay={key * 100} triggerOnce>
                    <Story key={key}>
                      <StoryImage
                        src={story.src}
                        alt={story.type}
                        title={story.title}
                      />
                      <Type>{story.type}</Type>
                      <Description aria-label={story.title}>
                        {story.shortDescription}
                      </Description>
                    </Story>
                  </Zoom>
                </>
              );
            })}
          </CustomerStoriesContainer>
          <ButtonGroup>
            <Button
              color="#031f48"
              onClick={() => navigateAndTrack("/contact", "Book now click")}
              aria-label="Click to go to the customer stories page"
            >
              Book Now
            </Button>
          </ButtonGroup>
        </AnotherSection>
        <Separator src={slantedLineSeparator} alt="" />
        <SectionContainer color="#F7F8FC">
          <Section color="#F7F8FC" templateColumns="1fr">
            <Slide direction="right" triggerOnce>
              <TextContainer>
                <Text>Testimonials</Text>
                <Slider content={testimonials} />
              </TextContainer>
            </Slide>
          </Section>
        </SectionContainer>
        <BottomSeparator src={slantedLineSeparator} alt="" />
        <AnotherSection color="#fff">
          <Text>Certificates</Text>
          <CustomerStoriesContainer>
            {certificates.map((story, key) => {
              return (
                <>
                  <Zoom key={key} delay={key * 100} triggerOnce>
                    <Story key={key}>
                      <CertificateImage src={story.src} alt={story.type} />
                      <Type>{story.type}</Type>
                      <Description aria-label={story.alt}>
                        {story.skills}
                      </Description>
                    </Story>
                  </Zoom>
                </>
              );
            })}
          </CustomerStoriesContainer>
          <ButtonGroup>
            <Button color="#031f48" onClick={() => handleViewResume()}>
              View Resume
            </Button>
          </ButtonGroup>
        </AnotherSection>
        <Separator src={singleLineWave} alt="" />
        <ContactMini heading=" " content="Let's connect" />
        <Footer
          separator={slantedLineSeparatorDark}
          color="#fff"
          logo={logoLight}
        />
      </SectionContainer>
    </>
  );
};
