import React from "react";
import { Container, SkillPill, SkillsContainer } from "./tech-project.styles";
import {ProjectBulletProps} from "./tech-project.types"

export const ProjectBulletPoint = ({
  startText,
  endText,
  skills,
}: ProjectBulletProps) => {
  return (
    <Container>
      <p>
        <b>{startText}</b> {endText}
      </p>
      {skills && (
        <SkillsContainer>
          {skills?.map((skill) => {
            return <SkillPill>{skill}</SkillPill>;
          })}
        </SkillsContainer>
      )}
    </Container>
  );
};
