import React, { useState } from "react";
import {
  Container,
  TestimonialContainer,
  Image,
  PrevArrow,
  NextArrow,
  ArrowGroup,
} from "./slider.styles";
import { useViewportDimensions } from "../../hooks/viewPortDimensions";

interface IContent {
  id: number;
  img: string;
  caption: string;
}

interface SliderProps {
  content: IContent[];
}

export const Slider = ({ content }: SliderProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % content.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? content.length - 1 : prevIndex - 1
    );
  };

  const { width } = useViewportDimensions();
  const isMobile = width <= 950;
  const isTablet = width <= 1250 && width >= 951;
  const isDesktop = width > 1250;

  const displayArrowsAsGroup = isDesktop || isMobile;

  return (
    <Container className="testimonial-slider">
      {isTablet && (
        <PrevArrow color="#031f48" onClick={handlePrev} isMobile={isMobile} />
      )}
      <TestimonialContainer className="testimonial" isMobile={isMobile}>
        <Image src={content[currentIndex].img} alt="" isMobile={isMobile} />
        {displayArrowsAsGroup && (
          <ArrowGroup>
            <PrevArrow
              color="#031f48"
              onClick={handlePrev}
              isMobile={isMobile}
            />
            <NextArrow
              color="#031f48"
              onClick={handleNext}
              isMobile={isMobile}
            />
          </ArrowGroup>
        )}
      </TestimonialContainer>
      {isTablet && (
        <NextArrow color="#031f48" onClick={handleNext} isMobile={isMobile} />
      )}
    </Container>
  );
};
