import styled from "styled-components";

export const Container = styled.div`
`;

export const SkillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const SkillPill = styled.div`
  color: #fff;
  font-weight: 600;
  background-color: #031f48;
  margin: 4px;
  padding: 4px;
  border-radius: 2px;
  box-shadow: 3px 2px 5px #031f48;
`;
