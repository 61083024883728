import styled, { css } from "styled-components";

interface StyleProps {
  isMobile: boolean;
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TestimonialContainer = styled.div`
  margin: 24px;
`;

const Text = styled.p<StyleProps>(({isMobile}: StyleProps)=>css`
  font-size: ${isMobile? '18px' : '24px'};
  text-align: center;
`);


const AuthorText = styled(Text)`
  font-weight: 600;
  text-align: center;
`;

const ArrowGroup = styled.div`
  display: flex;
  justify-content: center;
`;

const PrevArrow = styled.img<StyleProps>(
  ({ isMobile }: StyleProps) => css`
    border-radius: 50%;
    width: ${isMobile? '25px' : '50px'};
    cursor: pointer;
    margin: ${isMobile ? '0 12px' : '0 24px'}; 
    border: ${isMobile ? '2px solid #031f48' : 'none'};
    padding: ${isMobile ? '12px' : '0px'};

  `
);

const NextArrow = styled(PrevArrow)`
  transform: rotate(180deg);
`;

export {
  Container,
  TestimonialContainer,
  Text,
  AuthorText,
  ArrowGroup,
  PrevArrow,
  NextArrow,
};
