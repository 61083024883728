import {
  slantedLineSeparator,
  slantedLineSeparatorDark,
  logoLight,
  logoDark,
  selfie,
  docFileDark,
  pdfDark,
} from "../../assets";
import {
  testimonials,
  technicalProjects,
  experience,
  skills,
  links,
  cvCertificates,
  handleDownload,
} from "../../helpers";
import {
  Links,
  CvSection,
  CvSubHeading,
  BannerText as Slide,
  Section,
  SectionContainer,
  NameAndDocContainer,
  Separator,
  TextContainer,
  DocContainer,
  Text,
  Image,
  Name,
  Link,
  BottomSeparator,
  Document,
  Cv,
  HeadingOuterContainer,
  HeadingTextContainer,
  HeadingImageContainer,
} from "./resume.styles";
import { Footer } from "../../components/footer";
import { Background } from "../../components/background";
import { ContactMini } from "../../components/contact-mini";
import { Slider as TestimonialSlider } from "../../components/slider/slider";
import { ProjectBulletPoint } from "../../components/tech-project-bullet";
import { CvLinks } from "../../components/cv-links";

export const Resume = () => {
  return (
    <>
      <Background />
      <Separator src={slantedLineSeparator} alt="" />
      <CvSection>
        <Cv>
          <HeadingOuterContainer>
            <HeadingTextContainer>
              <NameAndDocContainer>
                <Name src={logoDark} alt="renee louise" />
                <DocContainer>
                  <Document
                    src={docFileDark}
                    alt="click to download cv in word format"
                    onClick={() => handleDownload("./cv.docx")}
                  />
                  <Document
                    src={pdfDark}
                    alt="click to download cv in pdf format"
                    onClick={() => handleDownload("./cv.pdf")}
                  />
                </DocContainer>
              </NameAndDocContainer>
              <Links>
                {links.map((link) => (
                  <Link>
                    <CvLinks
                      icon={link.icon}
                      alt={link.alt}
                      text={link.text}
                      url={link.url}
                    />
                  </Link>
                ))}
              </Links>

              <p>
                Economics graduate and Senior Software Engineer with over 6
                years of experience in full-stack development, specialising in
                React and TypeScript. I have a proven track record of
                architecting and delivering scalable, high-performance web
                applications in agile environments. I excel at translating
                complex technical requirements for stakeholders, driving
                technical initiatives, and leading high-performing teams. Known
                for meticulous documentation and problem-solving, I actively
                mentor developers and contribute to the tech community by
                hosting the Blaq Byte podcast, where I support underrepresented
                groups in tech by providing industry insights, career advice,
                and mentoring opportunities. Additionally, I speak at industry
                events and meetups with audiences of up to 150 attendees.
                Seeking to bring my expertise to a dynamic environment where I
                can continue to innovate and lead.
              </p>
            </HeadingTextContainer>
            <HeadingImageContainer>
              <Image src={selfie} alt="picture of Renée smiling" />
            </HeadingImageContainer>
          </HeadingOuterContainer>

          <CvSubHeading>Technical Projects</CvSubHeading>
          <ul>
            {technicalProjects.map((project) => (
              <li>
                <ProjectBulletPoint
                  startText={project.startText}
                  endText={project.endText}
                  skills={project.skills}
                />
              </li>
            ))}
          </ul>
          <CvSubHeading>Experience</CvSubHeading>
          <ul>
            {experience.map((project) => (
              <li>
                <ProjectBulletPoint
                  startText={project.startText}
                  endText={project.endText}
                  skills={project.skills}
                />
              </li>
            ))}
          </ul>

          <CvSubHeading>Education &amp; Certificates</CvSubHeading>
          <ul>
            {cvCertificates.map((project) => (
              <li>
                <ProjectBulletPoint
                  startText={project.startText}
                  endText={project.endText}
                  skills={project.skills}
                />
              </li>
            ))}
          </ul>
          <CvSubHeading>Skills</CvSubHeading>
          <ul>
            {skills.map((project) => (
              <li>
                <ProjectBulletPoint
                  startText={project.startText}
                  endText={project.endText}
                  skills={project.skills}
                />
              </li>
            ))}
          </ul>

          <CvSubHeading>Community Leadership &amp; initiatives</CvSubHeading>
          <ul>
            <li>
              <ProjectBulletPoint
                startText="Founder &amp; Podcast Host, Blaq Byte: "
                endText="Host and produce a podcast supporting underrepresented groups in tech, featuring industry experts and professionals to share key insights, career advice, and mentoring opportunities for career switchers and tech enthusiasts."
              />
            </li>
          </ul>
        </Cv>
      </CvSection>
      <SectionContainer color="#fff">
        <BottomSeparator src={slantedLineSeparator} alt="" />
        <Section color="#fff" templateColumns="1fr">
          <Slide direction="right" triggerOnce>
            <TextContainer>
              <Text>Testimonials</Text>
              <TestimonialSlider content={testimonials} />
            </TextContainer>
          </Slide>
        </Section>
        <Separator src={slantedLineSeparator} alt="" />
        <ContactMini color="#F7F8FC" />
        <Footer
          separator={slantedLineSeparatorDark}
          color="#F7F8FC"
          logo={logoLight}
        />
      </SectionContainer>
    </>
  );
};
