import {
  wavesSeparator,
  // lineSeparator,
  slantedLineSeparator,
  slantedLineSeparatorDark,
  logoLight,
  // arrow,
  athenaHack,
  glowingInTechh,
  triangirls,
} from "../../assets";
import { Fade, Slide } from "react-awesome-reveal";
import { testimonials, phrases } from "../../helpers";
import {
  Container,
  MainHeading,
  SubHeading,
  HeadingSubtext,
  Section,
  SectionContainer,
  // ImageWrapper,
  Separator,
  TextContainer,
  Text,
  TextContent,
  // AnotherSection,
  // ServicesContainer,
  // Service,
  // ServiceText,
  // ArrowButton,
  // Arrow,
  // Image,
  // Description,
  // ZoomAnimationContainer as Zoom,
  BottomSeparator,
  UniqueSection,
} from "./about.styles";
import { Footer } from "../../components/footer";
import { Background } from "../../components/background";
import { ContactMini } from "../../components/contact-mini";
import { useViewportDimensions } from "../../hooks/viewPortDimensions";
import { TypingEffect } from "../../components/typing-effect/typing-effect";
import { Slider } from "../../components/photo-slider/slider";
import { Slider as TestimonialSlider } from "../../components/slider/slider";

export const About = () => {

  const slideContent = [
    {
      id: 1,
      img: glowingInTechh,
      caption: "",
    },
    {
      id: 2,
      img: athenaHack,
      caption: "",
    },
    {
      id: 3,
      img: triangirls,
      caption: "",
    },
  ];


  // check for mobile dimensions
  const { width } = useViewportDimensions();
  const isMobile = width <= 990;
  const isTablet = width <= 1250;

  return (
    <>
      <Background />
      <Container>
        <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
          <MainHeading>About</MainHeading>
        </Fade>
        <Slide triggerOnce>
          <SubHeading>Renée-Louise</SubHeading>
          <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
            <HeadingSubtext isMobile={isMobile}>
              A passionate and results-driven
              <span style={{ marginLeft: "6px" }}>
                <TypingEffect phrases={phrases} />
              </span>
            </HeadingSubtext>
          </Fade>
        </Slide>
      </Container>
      <Separator src={wavesSeparator} alt="" />
      <SectionContainer color="#fff">
        <UniqueSection isMobile={isTablet}>
          <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
            <TextContent>
              Meet Renée, an individual propelled by an unwavering passion for
              problem-solving, navigating a distinctive professional journey
              that seamlessly integrates Economics, Education, and Technology.
              Armed with a degree in Economics from the University of Leicester,
              she embarked on a one-year stint as a Mathematics teacher at a
              secondary school, an unexpected yet enriching chapter in her
              story.
              <br />
              <br />
              Driven by a fervor for untangling complex challenges, she
              transitioned into the dynamic field of engineering. Undertaking
              intensive bootcamps generously sponsored by industry titans such
              as GCHQ, Goldman Sachs, and Beamery, she honed her technical
              acumen, culminating in a transformation into a results-driven
              Software Engineer. Currently, she serves as a Software Engineer at
              a unicorn scale-up, thriving in an environment that values
              creativity and adaptability.
              <br />
              <br />
              Beyond coding, she has discovered a newfound passion for
              consulting, extending her impact beyond lines of code to guide
              organizations towards sustainable growth and innovation. In
              addition to her role as a Software Engineer, she actively gives
              back to the community as a Full Stack Instructor with Code First
              Girls. This role reflects her commitment to empowering the next
              generation of tech enthusiasts, sharing knowledge, and fostering
              diversity in the industry.
              <br />
              <br />
              But that's not all – she is a sought-after speaker and has graced
              events hosted by Coding Black Females, Glowing in Tech, Aldemore
              Bank, Academy, and Triangirls. Her engaging talks delve into
              topics that resonate with the tech community, providing insights,
              inspiration, and fostering connections. As a community builder,
              she hosts the Blaq Byte Podcast, bringing together like-minded
              individuals to exchange ideas and insights.
              <br />
              <br />
              As she continues to traverse the ever-evolving tech landscape,
              juggling roles as an engineer, instructor, community builder,
              speaker, and founder, she remains steadfast in her commitment to
              excellence and exudes genuine enthusiasm for helping businesses
              thrive in the digital age.
            </TextContent>
          </Fade>
          <Slide direction="right" triggerOnce>
            <Slider content={slideContent} />
          </Slide>
        </UniqueSection>
        {/* <Separator src={lineSeparator} alt="" />
        <AnotherSection>
          <Text>Let's work together</Text>
          <ServicesContainer>
            {softwareServices.map((service, key) => {
              return (
                <>
                  <Zoom key={key} delay={key * 100} triggerOnce>
                    <Service
                      onClick={() => window.open(`${service.url}`)}
                      key={key}
                      aria-label={`Click to read more about ${service.offering}`}
                    >
                      <ImageWrapper>
                        <Image
                          src={service.src}
                          alt={service.alt}
                        />
                      </ImageWrapper>
                      <ServiceText>{service.offering}</ServiceText>
                      <Description>{service.shortDescription}</Description>
                   
                          <ArrowButton
                            key={key}
                            aria-label={`Click to go to the ${service.offering} page`}
                          >
                            <Arrow src={arrow} alt="" />
                          </ArrowButton>
                     
                    </Service>
                  </Zoom>
                </>
              );
            })}
          </ServicesContainer>
        </AnotherSection> */}
        <Separator src={slantedLineSeparator} alt="" />
        <Section color="#F7F8FC" templateColumns="1fr">
          <Slide direction="right" triggerOnce>
            <TextContainer>
              <Text>Testimonials</Text>
              <TestimonialSlider content={testimonials} />
            </TextContainer>
          </Slide>
        </Section>
        <BottomSeparator src={slantedLineSeparator} alt="" />
        <ContactMini color="#fff" />
        <Footer
          separator={slantedLineSeparatorDark}
          color="#fff"
          logo={logoLight}
        />
      </SectionContainer>
    </>
  );
};
