import styled, {css} from "styled-components";

interface StyleProps {
  isDisabled: boolean;
}

export const FormContainer = styled.form`
  padding: 36px 36px 52px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #ededec;
  background-color: #fff;
  border: none;
  z-index: 1;
  box-shadow: 0 8px 8px -4px grey;
  position: relative;

  @media screen and (max-width: 850px) {
    width: fit-content;
  }


`;

export const Text = styled.p`
font-size:12px;
margin-bottom:48px;
`;

export const FormSuccessContainer = styled.form`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ededec;
  border: none;
  width: 50%;

  @media screen and (max-width: 825px) {
    width: 100%;
  }
`;

export const FormHeading = styled.h1`
  margin: 0;
  color: #031f48;
`;
export const FormSubheading = styled.p``;

export const Fieldset = styled.fieldset`
  all: unset;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

export const Legend = styled.legend`
  visibility: hidden;
`;
export const Input = styled.input`
  height: 32px;
  margin-bottom: 8px;
  border: 0.5px solid grey;
  border-radius: 4px;
  padding: 4px;
  padding-left:12px;
  font-size: 14px;
`;

export const TextArea = styled.textarea`
  height: 100px;
  resize: none;
  font-family: inherit;
  font-size: 14px;
  margin-bottom: 8px;
  border: 0.5px solid grey;
  border-radius: 4px;
  padding: 4px;
  padding-left: 12px;
`;
export const SubmitButton = styled.button<StyleProps>(
  ({ isDisabled }: StyleProps) => css`
    width: fit-content;
    height: fit-content;
    padding: 8px 20px;
    font-weight: 400px;
    font-family: inherit;
    border-radius: 4px;
    border: none;
    color: #fff;
    position: absolute;
    bottom: 5px;
    right: 22px;
    font-size: 16px;
    font-weight: 200px;
    background-color: ${isDisabled ? 'grey' : '#150d6b'};
    cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
    margin: 12px;
    margin-top: 12px;

    &:hover {
      background-color: ${isDisabled ? 'grey' : '#fff'};
      outline: ${isDisabled ? 'none' : '1px solid #150d6b'};
      color: ${isDisabled ? '#fff' : '#150d6b'};
      transition: all 0.2s ease-in-out 0s;
    }

    @media screen and (max-width: 825px) {
      right: 26px;
    }
  `
);
  


export const Toaster = styled.div``;

