import {
  wavesSeparator,
  slantedLineSeparatorDark,
  logoLight,
  glowingInTechh,
} from "../../assets";
import { Fade, Slide } from "react-awesome-reveal";
import { socialLinks, trackEvent } from "../../helpers";
import {
  Container,
  MainHeading,
  SubHeading,
  HeadingSubtext,
  SectionContainer,
  Separator,
  Icon,
  BannerContainer,
  ContactImage,
} from "./contact.styles";
import { Footer } from "../../components/footer";
import { Background } from "../../components/background";
import { useViewportDimensions } from "../../hooks/viewPortDimensions";
import { LuHeartHandshake } from "react-icons/lu";
import { ContactForm } from "../../components/form";


export const Contact = () => {

  const { width } = useViewportDimensions();
  const isMobile = width <= 990;

    const navigateAndTrack = (navigateTo: string, event: string) => {
      window.open(navigateTo, "_blank");
      trackEvent(event);
    };

  return (
    <>
      <Background />
      <BannerContainer>
        <Container>
          <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
            <MainHeading>
              We'd love
              <span style={{ margin: "0 8px" }}>
                <LuHeartHandshake />{" "}
              </span>
              to connect
            </MainHeading>
          </Fade>
          <Slide triggerOnce>
            <SubHeading>Get in touch</SubHeading>
            <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
              <HeadingSubtext isMobile={isMobile}>
                {socialLinks.map((link) => (
                  <Icon
                    src={link.src.light}
                    alt={link.alt}
                    onClick={() =>
                      navigateAndTrack(`${link.url}`, `${link.alt} clicked`)
                    }
                  />
                ))}
              </HeadingSubtext>
            </Fade>
          </Slide>
        </Container>
      </BannerContainer>
      <Separator src={wavesSeparator} alt="" />
      <SectionContainer color="#fff" templateColumns="1fr 1fr">
        <ContactForm />
        <ContactImage src={glowingInTechh} alt="Renee speaking on microphone" />
      </SectionContainer>
      <Footer
        separator={slantedLineSeparatorDark}
        color="#fff"
        logo={logoLight}
      />
    </>
  );
};
