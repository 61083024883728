import { Dispatch, SetStateAction } from "react";
import { socialLinks } from "../../helpers";
import {
  MobileNavLinks as NavLinks,
  MobileMenuItem as NavLink,
  SocialLinks,
  SocialLink,
  SocialMediaButton,
} from "./navbar.styles";

interface MobileNavProps {
  setIsMenuIconClicked: Dispatch<SetStateAction<boolean>>;
}

export const MobileNav = ({ setIsMenuIconClicked }: MobileNavProps) => {
  return (
    <>
      <NavLinks direction="left">
        <NavLink to="/" onClick={() => setIsMenuIconClicked(false)}>
          Home
        </NavLink>
        <NavLink to="/about" onClick={() => setIsMenuIconClicked(false)}>
          About
        </NavLink>
        <NavLink to="/resume" onClick={() => setIsMenuIconClicked(false)}>
          Resume
        </NavLink>
        {/* <NavLink to="/projects" onClick={() => setIsMenuIconClicked(false)}>
          Projects
        </NavLink> */}
        <NavLink to="/podcast" onClick={() => setIsMenuIconClicked(false)}>
          Podcast
        </NavLink>
        <NavLink to="/contact" onClick={() => setIsMenuIconClicked(false)}>
          Contact
        </NavLink>
      </NavLinks>
      <SocialLinks>
        {socialLinks.map((link, key) => {
          return (
            <SocialMediaButton onClick={() => window.open(`${link.url}`)}>
              <SocialLink src={link.src.light} alt={link.alt} key={key} />
            </SocialMediaButton>
          );
        })}
      </SocialLinks>
    </>
  );
};
