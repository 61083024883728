import { trackEvent } from "../../helpers";
import { Container, Icon, Text } from "./cv-links.styles";

interface LinkProps {
  icon: string;
  text: string;
  url: string;
  alt?: string;
}
export const CvLinks = ({ icon, text, alt, url }: LinkProps) => {
  const navigateAndTrack = (navigateTo: string, event: string) => {
    window.open(navigateTo, "_blank");
    trackEvent(event);
  };

  return (
    <Container onClick={() => navigateAndTrack(url, `${url} clicked`)}>
      <Icon src={icon} alt={alt || ""} />
      <Text>{text}</Text>
    </Container>
  );
};
