import React, { useState } from "react";
import {
  Container,
  TestimonialContainer,
  Text,
  AuthorText,
  PrevArrow,
  NextArrow,
  ArrowGroup,
} from "./slider.styles";
import { arrowDark } from "../../assets";
import { useViewportDimensions } from "../../hooks/viewPortDimensions";
import { trackEvent } from "../../helpers";

interface IContent {
  id: number;
  text: string;
  author: string;
}

interface SliderProps {
  content: IContent[];
}

export const Slider = ({ content }: SliderProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % content.length);
    trackEvent("View more testimonials");
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? content.length - 1 : prevIndex - 1
    );
    trackEvent("View more testimonials");
  };

  const { width } = useViewportDimensions();
  const isMobile = width <= 728;

  return (
    <Container className="testimonial-slider">
      {!isMobile && (
        <PrevArrow
          src={arrowDark}
          alt="forward arrow"
          onClick={handlePrev}
          isMobile={isMobile}
        />
      )}
      <TestimonialContainer className="testimonial">
        <i>
          <Text isMobile={isMobile}>{content[currentIndex].text}</Text>
        </i>
        <AuthorText isMobile={isMobile} className="author">
          - {content[currentIndex].author}
        </AuthorText>
        {isMobile && (
          <ArrowGroup>
            <PrevArrow
              src={arrowDark}
              alt="forward arrow"
              onClick={handlePrev}
              isMobile={isMobile}
            />
            <NextArrow
              src={arrowDark}
              alt="forward arrow"
              onClick={handleNext}
              isMobile={isMobile}
            />
          </ArrowGroup>
        )}
      </TestimonialContainer>
      {!isMobile && (
        <NextArrow
          src={arrowDark}
          alt="forward arrow"
          onClick={handleNext}
          isMobile={isMobile}
        />
      )}
    </Container>
  );
};
